import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { colors } from '@shared/theme/colours';

import { styles } from './AgentSocietyLogo.styles';

export interface IconProps extends WithStyles<typeof styles> {
  color?: string;
  onClick?: () => void;
}

const AgentSocietyLogoIconComponent: FC<IconProps> = ({ classes, color = colors.white.lightest, onClick }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="33"
      viewBox="0 0 38 33"
      fill="none"
      classes={{ root: classes.root }}
      onClick={onClick}
    >
      <path
        d="M19.0108 11.2977L7.40185 31.6771C7.10373 32.2347 6.3028 32.2347 5.92014 31.6771L1.19022 23.385C0.936593 22.9578 0.936593 22.4856 1.19022 22.0585L12.688 1.89938C12.7725 1.72851 12.9861 1.64307 13.1952 1.64307C13.4044 1.64307 13.6179 1.72851 13.7025 1.89938L13.7559 1.99831L19.0108 11.2977Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M35.6833 21.892H25.1155L18.984 11.2527C15.4332 5.01562 13.7023 1.89936 13.7023 1.89936C13.6178 1.72848 13.4042 1.64304 13.1951 1.64304C12.9859 1.64304 12.7723 1.72848 12.6878 1.89936C12.9859 1.34176 13.5777 1 14.2541 1H24.061C24.5282 1 24.9509 1.25632 25.16 1.68351L36.195 20.9927C36.462 21.4873 36.1016 21.892 35.6878 21.892H35.6833Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M36.2664 21.1276C36.5778 21.6987 36.5734 22.1889 36.2797 22.7015L31.2072 31.6726C31.0381 31.9289 30.7845 32.0998 30.4863 32.0998H24.693C24.3948 32.0998 24.1412 31.9289 23.9721 31.6726L19.1532 23.1692C18.8551 22.6116 19.2378 21.8876 19.8741 21.8876H35.728C36.0172 21.8876 36.4977 21.5188 36.1952 20.9882L36.2664 21.1231V21.1276Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};

export const AgentSocietyLogoIcon = withStyles(styles)(AgentSocietyLogoIconComponent);
