import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './CoinsHand.styles';

export interface CoinsHandIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const CoinsHandIconComponent: FC<CoinsHandIconProps> = ({ classes, color = '#101828' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_4834_151)">
        <path
          d="M11.2744 6.98096C10.7974 7.32104 10.2136 7.52108 9.58317 7.52108C7.97234 7.52108 6.6665 6.21524 6.6665 4.60441C6.6665 2.99358 7.97234 1.68774 9.58317 1.68774C10.6273 1.68774 11.5433 2.23643 12.0586 3.0612M4.99984 16.7604H7.17508C7.45869 16.7604 7.74057 16.7941 8.01551 16.8616L10.3139 17.4201C10.8126 17.5416 11.3322 17.5534 11.8361 17.4556L14.3773 16.9612C15.0486 16.8304 15.6662 16.5089 16.1501 16.0382L17.9481 14.2892C18.4615 13.7905 18.4615 12.9814 17.9481 12.482C17.4858 12.0323 16.7538 11.9817 16.2308 12.363L14.1353 13.8918C13.8352 14.1112 13.4701 14.2293 13.0945 14.2293H11.0711L12.3591 14.2292C13.085 14.2292 13.6731 13.6572 13.6731 12.951V12.6954C13.6731 12.109 13.2628 11.5977 12.6782 11.456L10.6903 10.9725C10.3668 10.8941 10.0355 10.8544 9.70245 10.8544C8.89845 10.8544 7.44308 11.5201 7.44308 11.5201L4.99984 12.5418M16.6665 5.43774C16.6665 7.04858 15.3607 8.35441 13.7498 8.35441C12.139 8.35441 10.8332 7.04858 10.8332 5.43774C10.8332 3.82691 12.139 2.52108 13.7498 2.52108C15.3607 2.52108 16.6665 3.82691 16.6665 5.43774ZM1.6665 12.1877L1.6665 17.0211C1.6665 17.4878 1.6665 17.7211 1.75733 17.8994C1.83723 18.0562 1.96471 18.1837 2.12151 18.2636C2.29977 18.3544 2.53313 18.3544 2.99984 18.3544H3.6665C4.13321 18.3544 4.36657 18.3544 4.54483 18.2636C4.70163 18.1837 4.82911 18.0562 4.90901 17.8994C4.99984 17.7211 4.99984 17.4878 4.99984 17.0211V12.1877C4.99984 11.721 4.99984 11.4877 4.90901 11.3094C4.82911 11.1526 4.70163 11.0251 4.54483 10.9452C4.36657 10.8544 4.13321 10.8544 3.6665 10.8544L2.99984 10.8544C2.53313 10.8544 2.29977 10.8544 2.12151 10.9452C1.96471 11.0251 1.83723 11.1526 1.75733 11.3094C1.6665 11.4877 1.6665 11.721 1.6665 12.1877Z"
          stroke={color}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4834_151">
          <rect width="20" height="20" fill="white" transform="translate(0 0.0209961)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const CoinsHandIcon = withStyles(styles)(CoinsHandIconComponent);
