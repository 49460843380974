import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './FileCheck2.styles';

export interface FileCheck2IconProps extends WithStyles<typeof styles> {
  color?: string;
}

const FileCheck2IconComponent: FC<FileCheck2IconProps> = ({ classes, color = '#101828' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      classes={{ root: classes.root }}
    >
      <g clipPath="url(#clip0_4834_11416)">
        <path
          d="M16.6668 10.4377V5.68774C16.6668 4.28761 16.6668 3.58755 16.3943 3.05277C16.1547 2.58236 15.7722 2.19991 15.3018 1.96023C14.767 1.68774 14.067 1.68774 12.6668 1.68774H7.3335C5.93336 1.68774 5.2333 1.68774 4.69852 1.96023C4.22811 2.19991 3.84566 2.58236 3.60598 3.05277C3.3335 3.58755 3.3335 4.28761 3.3335 5.68774V14.3544C3.3335 15.7545 3.3335 16.4546 3.60598 16.9894C3.84566 17.4598 4.22811 17.8422 4.69852 18.0819C5.2333 18.3544 5.93336 18.3544 7.3335 18.3544H10.0002M11.6668 9.18774H6.66683M8.3335 12.5211H6.66683M13.3335 5.85441H6.66683M12.0835 15.8544L13.7502 17.5211L17.5002 13.7711"
          stroke={color}
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4834_11416">
          <rect width="20" height="20" fill="white" transform="translate(0 0.0209961)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const FileCheck2Icon = withStyles(styles)(FileCheck2IconComponent);
