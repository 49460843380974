import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './Building.styles';

export interface BuildingIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const BuildingIconComponent: FC<BuildingIconProps> = ({ classes, color = '#515D70' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M12.5 17.5209V13.0209C12.5 12.5542 12.5 12.3208 12.4092 12.1425C12.3293 11.9857 12.2018 11.8583 12.045 11.7784C11.8667 11.6875 11.6334 11.6875 11.1667 11.6875H8.83333C8.36662 11.6875 8.13327 11.6875 7.95501 11.7784C7.79821 11.8583 7.67072 11.9857 7.59083 12.1425C7.5 12.3208 7.5 12.5542 7.5 13.0209V17.5209M15.8333 17.5209V5.18754C15.8333 4.25412 15.8333 3.78741 15.6517 3.43089C15.4919 3.11729 15.2369 2.86232 14.9233 2.70253C14.5668 2.52087 14.1001 2.52087 13.1667 2.52087H6.83333C5.89991 2.52087 5.4332 2.52087 5.07668 2.70253C4.76308 2.86232 4.50811 3.11729 4.34832 3.43089C4.16667 3.78741 4.16667 4.25412 4.16667 5.18754V17.5209M17.5 17.5209H2.5M7.91667 6.68754H7.925M12.0833 6.68754H12.0917M8.33333 6.68754C8.33333 6.91766 8.14679 7.10421 7.91667 7.10421C7.68655 7.10421 7.5 6.91766 7.5 6.68754C7.5 6.45742 7.68655 6.27087 7.91667 6.27087C8.14679 6.27087 8.33333 6.45742 8.33333 6.68754ZM12.5 6.68754C12.5 6.91766 12.3135 7.10421 12.0833 7.10421C11.8532 7.10421 11.6667 6.91766 11.6667 6.68754C11.6667 6.45742 11.8532 6.27087 12.0833 6.27087C12.3135 6.27087 12.5 6.45742 12.5 6.68754Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const BuildingIcon = withStyles(styles)(BuildingIconComponent);
