import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './CheckCircle.styles';
interface CheckCircleIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const CheckCircleIconComponent: FC<CheckCircleIconProps> = ({ classes, color = '#039855' }) => {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      classes={{ root: classes.root }}
    >
      <path
        d="M9.00008 15.0003L13.0001 19.0003L21.0001 11.0003M28.3334 15.0003C28.3334 22.3641 22.3639 28.3337 15.0001 28.3337C7.63628 28.3337 1.66675 22.3641 1.66675 15.0003C1.66675 7.63653 7.63628 1.66699 15.0001 1.66699C22.3639 1.66699 28.3334 7.63653 28.3334 15.0003Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const CheckCircleIcon = withStyles(styles)(CheckCircleIconComponent);
