import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './ClipboardCheck.styles';

export interface ClipboardCheckIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const ClipboardCheckIconComponent: FC<ClipboardCheckIconProps> = ({ classes, color = '#515D70' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M11.3334 3.35417C12.1084 3.35417 12.4958 3.35417 12.8138 3.43935C13.6765 3.67052 14.3504 4.34439 14.5815 5.20712C14.6667 5.52504 14.6667 5.91252 14.6667 6.6875V14.3542C14.6667 15.7543 14.6667 16.4544 14.3942 16.9891C14.1545 17.4595 13.7721 17.842 13.3017 18.0817C12.7669 18.3542 12.0668 18.3542 10.6667 18.3542H5.33337C3.93324 18.3542 3.23318 18.3542 2.6984 18.0817C2.22799 17.842 1.84554 17.4595 1.60586 16.9891C1.33337 16.4544 1.33337 15.7543 1.33337 14.3542V6.6875C1.33337 5.91252 1.33337 5.52504 1.41856 5.20712C1.64973 4.34439 2.3236 3.67052 3.18633 3.43935C3.50424 3.35417 3.89173 3.35417 4.66671 3.35417M5.50004 12.5208L7.16671 14.1875L10.9167 10.4375M6.00004 5.02083H10C10.4668 5.02083 10.7001 5.02083 10.8784 4.93001C11.0352 4.85011 11.1627 4.72263 11.2425 4.56583C11.3334 4.38757 11.3334 4.15421 11.3334 3.6875V3.02083C11.3334 2.55412 11.3334 2.32077 11.2425 2.14251C11.1627 1.98571 11.0352 1.85822 10.8784 1.77833C10.7001 1.6875 10.4668 1.6875 10 1.6875H6.00004C5.53333 1.6875 5.29997 1.6875 5.12172 1.77833C4.96491 1.85822 4.83743 1.98571 4.75754 2.14251C4.66671 2.32077 4.66671 2.55412 4.66671 3.02083V3.6875C4.66671 4.15421 4.66671 4.38757 4.75754 4.56583C4.83743 4.72263 4.96491 4.85011 5.12172 4.93001C5.29997 5.02083 5.53333 5.02083 6.00004 5.02083Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const ClipboardCheckIcon = withStyles(styles)(ClipboardCheckIconComponent);
