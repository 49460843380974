import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { colors } from '@shared/theme/colours';

import { styles } from './Users.styles';

export interface UsersIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const UsersIconComponent: FC<UsersIconProps> = ({ classes, color = colors.white.lightest }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M14.6667 13V11.6667C14.6667 10.4241 13.8168 9.38004 12.6667 9.08401M10.3333 1.19384C11.3106 1.58943 12 2.54754 12 3.66667C12 4.78579 11.3106 5.7439 10.3333 6.13949M11.3333 13C11.3333 11.7575 11.3333 11.1362 11.1303 10.6462C10.8597 9.99277 10.3406 9.47364 9.68716 9.20299C9.1971 9 8.57585 9 7.33334 9H5.33334C4.09083 9 3.46957 9 2.97951 9.20299C2.32611 9.47364 1.80697 9.99277 1.53632 10.6462C1.33334 11.1362 1.33334 11.7575 1.33334 13M9 3.66667C9 5.13943 7.8061 6.33333 6.33334 6.33333C4.86058 6.33333 3.66667 5.13943 3.66667 3.66667C3.66667 2.19391 4.86058 1 6.33334 1C7.8061 1 9 2.19391 9 3.66667Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export const UsersIcon = withStyles(styles)(UsersIconComponent);
