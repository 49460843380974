import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './CalendarCheck.styles';

export interface BuildingIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const CalendarCheckIconComponent: FC<BuildingIconProps> = ({ classes, color = '#515D70' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M16.5 8.35417H1.5M12.3333 1.6875V5.02083M5.66667 1.6875V5.02083M6.5 13.3542L8.16667 15.0208L11.9167 11.2708M5.5 18.3542H12.5C13.9001 18.3542 14.6002 18.3542 15.135 18.0817C15.6054 17.842 15.9878 17.4595 16.2275 16.9891C16.5 16.4544 16.5 15.7543 16.5 14.3542V7.35417C16.5 5.95404 16.5 5.25397 16.2275 4.71919C15.9878 4.24879 15.6054 3.86633 15.135 3.62665C14.6002 3.35417 13.9001 3.35417 12.5 3.35417H5.5C4.09987 3.35417 3.3998 3.35417 2.86502 3.62665C2.39462 3.86633 2.01217 4.24879 1.77248 4.71919C1.5 5.25397 1.5 5.95404 1.5 7.35417V14.3542C1.5 15.7543 1.5 16.4544 1.77248 16.9891C2.01217 17.4595 2.39462 17.842 2.86502 18.0817C3.3998 18.3542 4.09987 18.3542 5.5 18.3542Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const CalendarCheckIcon = withStyles(styles)(CalendarCheckIconComponent);
