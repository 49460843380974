import { IComment } from '@shared/models/comment';
import { IDisposalListItem } from '@shared/models/disposal/disposal';
import { Requirement } from '@shared/models/requirement/requirement';

export enum ViewingOutcome {
  Positive = 'positive',
  Closed = 'closed',
  Neutral = 'neutral',
  Negative = 'negative',
  Pending = 'pending',
  Cancelled = 'cancelled',
  None = 'none',
}
export interface Viewing {
  all_day: number;
  assignee_string: string;
  date_string: string;
  disposals: Array<IDisposalListItem>;
  from: string;
  ics_download: string;
  id: number;
  notes: string;
  organisation_id: number;
  originally_from: string;
  originally_to: string;
  outcome: ViewingOutcome;
  outcome_string: string;
  repeat_ends_on: string | null;
  repeat_monthly_type: number;
  repeat_never_ends: boolean;
  requirements: Requirement[];
  rrule: string | null;
  rrule_frequency: string | null;
  rrule_interval: string | null;
  rrule_monthlyByDay: string | null;
  rrule_monthlyGetByMonthDay: string | null;
  rrule_monthlySetPosition: string | null;
  rrule_text: string | null;
  rrule_until: string | null;
  society_acquisitions: string | null;
  society_disposals: string | null;
  society_members: string | null;
  status: number;
  title: string;
  to: string;
  type: string;
  type_string: string;
  comments: IComment[];
}
