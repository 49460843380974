import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './Mail.styles';

export interface IconProps extends WithStyles<typeof styles> {
  color?: string;
}

const MailIconComponent: FC<IconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      classes={{ root: classes.root }}
    >
      <path
        d="M1.66663 3.85425L8.47073 8.61712C9.0217 9.0028 9.29719 9.19564 9.59685 9.27034C9.86154 9.33632 10.1384 9.33632 10.4031 9.27034C10.7027 9.19564 10.9782 9.0028 11.5292 8.61712L18.3333 3.85425M5.66663 14.6876H14.3333C15.7334 14.6876 16.4335 14.6876 16.9683 14.4151C17.4387 14.1754 17.8211 13.793 18.0608 13.3226C18.3333 12.7878 18.3333 12.0877 18.3333 10.6876V5.35425C18.3333 3.95412 18.3333 3.25405 18.0608 2.71927C17.8211 2.24887 17.4387 1.86642 16.9683 1.62673C16.4335 1.35425 15.7334 1.35425 14.3333 1.35425H5.66663C4.26649 1.35425 3.56643 1.35425 3.03165 1.62673C2.56124 1.86642 2.17879 2.24887 1.93911 2.71927C1.66663 3.25405 1.66663 3.95412 1.66663 5.35425V10.6876C1.66663 12.0877 1.66663 12.7878 1.93911 13.3226C2.17879 13.793 2.56124 14.1754 3.03165 14.4151C3.56643 14.6876 4.26649 14.6876 5.66663 14.6876Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const MailIcon = withStyles(styles)(MailIconComponent);
