import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './ChevronDown.styles';

export interface ChevronDownProps extends WithStyles<typeof styles> {
  color?: string;
}
const ChevronDownIconComponent: FC<ChevronDownProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path d="M1 1L5 5L9 1" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export const ChevronDownIcon = withStyles(styles)(ChevronDownIconComponent);
