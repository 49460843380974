import { SvgIcon } from '@material-ui/core';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Pin.styles';

export interface PinIconProps extends WithStyles<typeof styles> {
  color?: string;
}

const PinIconComponent: React.FC<PinIconProps> = ({ classes, color = '#7E8899' }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M11.1694 20.8219L3.62695 28.3644M15.593 8.85592L13.5118 10.9371C13.3421 11.1069 13.2572 11.1917 13.1605 11.2592C13.0746 11.3191 12.982 11.3686 12.8846 11.4068C12.7748 11.4499 12.6571 11.4734 12.4217 11.5205L7.53576 12.4977C6.26602 12.7516 5.63115 12.8786 5.33414 13.2134C5.07539 13.505 4.95723 13.8952 5.01076 14.2814C5.07222 14.7247 5.53003 15.1825 6.44565 16.0981L15.8933 25.5457C16.8089 26.4614 17.2667 26.9192 17.71 26.9806C18.0962 27.0342 18.4864 26.916 18.778 26.6573C19.1128 26.3602 19.2397 25.7254 19.4937 24.4556L20.4709 19.5697C20.518 19.3343 20.5415 19.2166 20.5846 19.1068C20.6228 19.0094 20.6723 18.9168 20.7322 18.8309C20.7997 18.7342 20.8845 18.6493 21.0543 18.4796L23.1355 16.3984C23.244 16.2898 23.2983 16.2356 23.3579 16.1882C23.4109 16.1461 23.4671 16.1081 23.5258 16.0745C23.592 16.0367 23.6625 16.0065 23.8036 15.946L27.1294 14.5207C28.0997 14.1049 28.5848 13.897 28.8052 13.561C28.9979 13.2672 29.0668 12.9092 28.997 12.5648C28.9172 12.171 28.544 11.7978 27.7976 11.0514L20.94 4.19384C20.1936 3.4474 19.8204 3.07419 19.4266 2.99436C19.0822 2.92455 18.7242 2.99349 18.4304 3.1862C18.0944 3.40657 17.8865 3.89171 17.4707 4.86197L16.0454 8.18778C15.9849 8.32887 15.9547 8.39942 15.9169 8.46557C15.8833 8.52433 15.8453 8.58045 15.8032 8.63345C15.7558 8.6931 15.7015 8.74737 15.593 8.85592Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const PinIcon = withStyles(styles)(PinIconComponent);
