import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { colors } from '@shared/theme/colours';

import { styles } from './AgentSocietyTest.styles';

export interface IconProps extends WithStyles<typeof styles> {
  color?: string;
  onClick?: () => void;
}

const AgentSocietyTextIconComponent: FC<IconProps> = ({ classes, color = colors.white.lightest, onClick }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="122"
      height="16"
      viewBox="0 0 122 16"
      fill="none"
      classes={{ root: classes.root }}
      onClick={onClick}
    >
      <path
        d="M0.5 12.376L4.852 0.476H7.912L12.23 12.376H9.493L8.575 9.775H4.053L3.135 12.376H0.5ZM4.733 7.718H7.878L6.297 3.196L4.733 7.718Z"
        fill={color}
      />
      <path
        d="M21.4581 3.638V12.121C21.4581 13.2657 21.0784 14.178 20.3191 14.858C19.5597 15.538 18.3811 15.878 16.7831 15.878C15.5477 15.878 14.5504 15.606 13.7911 15.062C13.0317 14.518 12.6237 13.7473 12.5671 12.75H14.9811C15.0944 13.1693 15.3154 13.4923 15.6441 13.719C15.9841 13.9457 16.4261 14.059 16.9701 14.059C17.6501 14.059 18.1771 13.889 18.5511 13.549C18.9251 13.209 19.1121 12.7047 19.1121 12.036V11.118C18.4774 11.9227 17.5764 12.325 16.4091 12.325C15.6384 12.325 14.9471 12.1437 14.3351 11.781C13.7231 11.4183 13.2414 10.9083 12.8901 10.251C12.5501 9.58233 12.3801 8.806 12.3801 7.922C12.3801 7.04933 12.5501 6.27867 12.8901 5.61C13.2414 4.94133 13.7231 4.42567 14.3351 4.063C14.9584 3.70033 15.6554 3.519 16.4261 3.519C17.5707 3.519 18.4774 3.961 19.1461 4.845L19.3501 3.638H21.4581ZM16.9701 10.404C17.6161 10.404 18.1317 10.1773 18.5171 9.724C18.9024 9.27067 19.0951 8.68133 19.0951 7.956C19.0951 7.21933 18.9024 6.61867 18.5171 6.154C18.1317 5.68933 17.6161 5.457 16.9701 5.457C16.3241 5.457 15.8027 5.68933 15.4061 6.154C15.0207 6.60733 14.8281 7.20233 14.8281 7.939C14.8281 8.67567 15.0207 9.27067 15.4061 9.724C15.8027 10.1773 16.3241 10.404 16.9701 10.404Z"
        fill={color}
      />
      <path
        d="M22.7893 8.024C22.7893 7.11733 22.9706 6.32967 23.3333 5.661C23.7073 4.981 24.2229 4.45967 24.8803 4.097C25.5489 3.723 26.3196 3.536 27.1923 3.536C28.0763 3.536 28.8526 3.71167 29.5213 4.063C30.2013 4.403 30.7283 4.89033 31.1023 5.525C31.4876 6.14833 31.6859 6.885 31.6973 7.735C31.6973 8.041 31.6746 8.313 31.6293 8.551H25.2883V8.619C25.3449 9.25367 25.5489 9.75233 25.9003 10.115C26.2516 10.4777 26.7333 10.659 27.3453 10.659C27.8326 10.659 28.2349 10.557 28.5523 10.353C28.8809 10.1377 29.0963 9.826 29.1983 9.418H31.5613C31.4706 9.996 31.2496 10.5173 30.8983 10.982C30.5469 11.4467 30.0823 11.815 29.5043 12.087C28.9263 12.3477 28.2633 12.478 27.5153 12.478C26.5406 12.478 25.6963 12.2967 24.9823 11.934C24.2796 11.5713 23.7356 11.0557 23.3503 10.387C22.9763 9.707 22.7893 8.91933 22.7893 8.024ZM29.3003 6.987C29.2209 6.45433 28.9999 6.04633 28.6373 5.763C28.2859 5.46833 27.8383 5.321 27.2943 5.321C26.7729 5.321 26.3309 5.474 25.9683 5.78C25.6169 6.07467 25.4073 6.477 25.3393 6.987H29.3003Z"
        fill={color}
      />
      <path
        d="M38.0222 3.536C39.0422 3.536 39.8809 3.85333 40.5382 4.488C41.1955 5.12267 41.5242 6.171 41.5242 7.633V12.376H39.1272V7.769C39.1272 6.26167 38.5379 5.508 37.3592 5.508C36.7359 5.508 36.2542 5.71767 35.9142 6.137C35.5742 6.55633 35.4042 7.14567 35.4042 7.905V12.376H33.0072V3.638H35.0982L35.3192 4.726C35.6252 4.352 35.9935 4.063 36.4242 3.859C36.8549 3.64367 37.3875 3.536 38.0222 3.536Z"
        fill={color}
      />
      <path
        d="M43.5955 5.661H42.1505V3.638H43.5955V1.19H45.9925V3.638H47.9815V5.661H45.9925V9.486C45.9925 9.792 46.0549 10.013 46.1795 10.149C46.3155 10.285 46.5365 10.353 46.8425 10.353H48.0835V12.376H46.0945C44.4285 12.376 43.5955 11.543 43.5955 9.877V5.661Z"
        fill={color}
      />
      <path
        d="M51.0491 9.469C51.0831 9.85434 51.2701 10.166 51.6101 10.404C51.9501 10.642 52.3864 10.761 52.9191 10.761C53.4291 10.761 53.8314 10.6817 54.1261 10.523C54.4208 10.353 54.5681 10.115 54.5681 9.809C54.5681 9.58233 54.5001 9.41233 54.3641 9.299C54.2281 9.18567 54.0354 9.10633 53.7861 9.061C53.5368 9.00433 53.1288 8.942 52.5621 8.874C51.7914 8.772 51.1511 8.64167 50.6411 8.483C50.1311 8.32433 49.7231 8.075 49.4171 7.735C49.1111 7.395 48.9581 6.94167 48.9581 6.375C48.9581 5.80833 49.1111 5.30967 49.4171 4.879C49.7344 4.437 50.1764 4.097 50.7431 3.859C51.3098 3.621 51.9614 3.502 52.6981 3.502C53.8881 3.51333 54.8458 3.76833 55.5711 4.267C56.3078 4.76567 56.7044 5.457 56.7611 6.341H54.4831C54.4491 6.01233 54.2734 5.746 53.9561 5.542C53.6501 5.32667 53.2534 5.219 52.7661 5.219C52.3128 5.219 51.9444 5.304 51.6611 5.474C51.3891 5.644 51.2531 5.87067 51.2531 6.154C51.2531 6.358 51.3268 6.511 51.4741 6.613C51.6214 6.715 51.8141 6.78867 52.0521 6.834C52.2901 6.87933 52.6868 6.93033 53.2421 6.987C54.4208 7.123 55.3218 7.378 55.9451 7.752C56.5798 8.11467 56.8971 8.74367 56.8971 9.639C56.8971 10.2057 56.7271 10.7043 56.3871 11.135C56.0584 11.5657 55.5938 11.9 54.9931 12.138C54.4038 12.3647 53.7181 12.478 52.9361 12.478C51.7234 12.478 50.7374 12.2117 49.9781 11.679C49.2188 11.135 48.8164 10.3983 48.7711 9.469H51.0491Z"
        fill={color}
      />
      <path
        d="M64.7016 8.5C64.7469 9.08933 64.9849 9.56533 65.4156 9.928C65.8576 10.2793 66.4412 10.455 67.1666 10.455C67.7672 10.455 68.2546 10.3303 68.6286 10.081C69.0139 9.82033 69.2066 9.46333 69.2066 9.01C69.2066 8.69267 69.0989 8.44333 68.8836 8.262C68.6796 8.06933 68.3849 7.922 67.9996 7.82C67.6256 7.70667 67.0419 7.57067 66.2486 7.412C65.4666 7.26467 64.8092 7.07767 64.2766 6.851C63.7439 6.613 63.3076 6.26733 62.9676 5.814C62.6389 5.34933 62.4746 4.73733 62.4746 3.978C62.4746 3.28667 62.6616 2.669 63.0356 2.125C63.4209 1.581 63.9479 1.156 64.6166 0.85C65.2966 0.532667 66.0616 0.374 66.9116 0.374C67.7842 0.374 68.5662 0.538334 69.2576 0.867C69.9602 1.18433 70.5099 1.62633 70.9066 2.193C71.3146 2.75967 71.5242 3.4 71.5356 4.114H69.0366C68.9799 3.604 68.7646 3.196 68.3906 2.89C68.0166 2.57267 67.5236 2.414 66.9116 2.414C66.3449 2.414 65.8916 2.533 65.5516 2.771C65.2116 3.009 65.0416 3.349 65.0416 3.791C65.0416 4.10833 65.1436 4.35767 65.3476 4.539C65.5516 4.709 65.8406 4.85067 66.2146 4.964C66.5886 5.066 67.1666 5.19067 67.9486 5.338C68.7419 5.48533 69.4106 5.67233 69.9546 5.899C70.4986 6.11433 70.9349 6.44867 71.2636 6.902C71.6036 7.344 71.7736 7.939 71.7736 8.687C71.7736 9.41233 71.5752 10.064 71.1786 10.642C70.7819 11.2087 70.2266 11.6563 69.5126 11.985C68.8099 12.3137 68.0166 12.478 67.1326 12.478C66.1806 12.478 65.3306 12.308 64.5826 11.968C63.8459 11.6167 63.2679 11.135 62.8486 10.523C62.4292 9.911 62.2139 9.22533 62.2026 8.466L64.7016 8.5Z"
        fill={color}
      />
      <path
        d="M77.3836 12.478C76.4656 12.478 75.6552 12.2967 74.9526 11.934C74.2612 11.56 73.7229 11.033 73.3376 10.353C72.9636 9.673 72.7766 8.891 72.7766 8.007C72.7766 7.123 72.9636 6.341 73.3376 5.661C73.7229 4.981 74.2612 4.45967 74.9526 4.097C75.6552 3.723 76.4656 3.536 77.3836 3.536C78.3016 3.536 79.1062 3.723 79.7976 4.097C80.5002 4.45967 81.0386 4.981 81.4126 5.661C81.7979 6.341 81.9906 7.123 81.9906 8.007C81.9906 8.90233 81.7979 9.69 81.4126 10.37C81.0386 11.0387 80.5002 11.56 79.7976 11.934C79.1062 12.2967 78.3016 12.478 77.3836 12.478ZM77.3836 10.523C78.0522 10.523 78.5792 10.2907 78.9646 9.826C79.3612 9.36133 79.5596 8.755 79.5596 8.007C79.5596 7.259 79.3612 6.65267 78.9646 6.188C78.5792 5.72333 78.0522 5.491 77.3836 5.491C76.7262 5.491 76.1992 5.72333 75.8026 6.188C75.4172 6.65267 75.2246 7.259 75.2246 8.007C75.2246 8.755 75.4172 9.36133 75.8026 9.826C76.1992 10.2907 76.7262 10.523 77.3836 10.523Z"
        fill={color}
      />
      <path
        d="M91.7079 9.061C91.5493 10.1263 91.0959 10.965 90.3479 11.577C89.6113 12.1777 88.6536 12.478 87.4749 12.478C86.5683 12.478 85.7749 12.2967 85.0949 11.934C84.4149 11.56 83.8879 11.033 83.5139 10.353C83.1513 9.673 82.9699 8.891 82.9699 8.007C82.9699 7.11167 83.1513 6.32967 83.5139 5.661C83.8879 4.981 84.4149 4.45967 85.0949 4.097C85.7863 3.723 86.5853 3.536 87.4919 3.536C88.6593 3.536 89.6113 3.83633 90.3479 4.437C91.0846 5.02633 91.5379 5.848 91.7079 6.902H89.2259C89.1239 6.47133 88.9199 6.13133 88.6139 5.882C88.3079 5.62133 87.9283 5.491 87.4749 5.491C86.8516 5.491 86.3529 5.72333 85.9789 6.188C85.6049 6.65267 85.4179 7.259 85.4179 8.007C85.4179 8.755 85.6049 9.36133 85.9789 9.826C86.3529 10.2907 86.8516 10.523 87.4749 10.523C87.9396 10.523 88.3249 10.3927 88.6309 10.132C88.9483 9.87133 89.1523 9.51433 89.2429 9.061H91.7079Z"
        fill={color}
      />
      <path
        d="M94.2122 0C94.6089 0 94.9376 0.130334 95.1982 0.391C95.4589 0.640334 95.5892 0.957667 95.5892 1.343C95.5892 1.72833 95.4589 2.05133 95.1982 2.312C94.9376 2.57267 94.6089 2.703 94.2122 2.703C93.8156 2.703 93.4812 2.57267 93.2092 2.312C92.9486 2.05133 92.8182 1.72833 92.8182 1.343C92.8182 0.957667 92.9486 0.640334 93.2092 0.391C93.4812 0.130334 93.8156 0 94.2122 0ZM93.0052 3.638H95.4022V12.376H93.0052V3.638Z"
        fill={color}
      />
      <path
        d="M96.7326 8.024C96.7326 7.11733 96.914 6.32967 97.2766 5.661C97.6506 4.981 98.1663 4.45967 98.8236 4.097C99.4923 3.723 100.263 3.536 101.136 3.536C102.02 3.536 102.796 3.71167 103.465 4.063C104.145 4.403 104.672 4.89033 105.046 5.525C105.431 6.14833 105.629 6.885 105.641 7.735C105.641 8.041 105.618 8.313 105.573 8.551H99.2316V8.619C99.2883 9.25367 99.4923 9.75233 99.8436 10.115C100.195 10.4777 100.677 10.659 101.289 10.659C101.776 10.659 102.178 10.557 102.496 10.353C102.824 10.1377 103.04 9.826 103.142 9.418H105.505C105.414 9.996 105.193 10.5173 104.842 10.982C104.49 11.4467 104.026 11.815 103.448 12.087C102.87 12.3477 102.207 12.478 101.459 12.478C100.484 12.478 99.6396 12.2967 98.9256 11.934C98.2229 11.5713 97.679 11.0557 97.2936 10.387C96.9196 9.707 96.7326 8.91933 96.7326 8.024ZM103.244 6.987C103.164 6.45433 102.943 6.04633 102.581 5.763C102.229 5.46833 101.782 5.321 101.238 5.321C100.716 5.321 100.274 5.474 99.9116 5.78C99.5603 6.07467 99.3506 6.477 99.2826 6.987H103.244Z"
        fill={color}
      />
      <path
        d="M107.478 5.661H106.033V3.638H107.478V1.19H109.875V3.638H111.864V5.661H109.875V9.486C109.875 9.792 109.938 10.013 110.062 10.149C110.198 10.285 110.419 10.353 110.725 10.353H111.966V12.376H109.977C108.311 12.376 107.478 11.543 107.478 9.877V5.661Z"
        fill={color}
      />
      <path
        d="M114.677 3.638L116.802 9.911L118.876 3.638H121.443L117.567 13.617C117.363 14.1497 117.17 14.5633 116.989 14.858C116.808 15.164 116.575 15.3907 116.292 15.538C116.02 15.6967 115.663 15.776 115.221 15.776H112.892V13.753H114.218C114.524 13.753 114.745 13.702 114.881 13.6C115.017 13.498 115.147 13.2997 115.272 13.005L115.493 12.427L112.127 3.638H114.677Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export const AgentSocietyTextIcon = withStyles(styles)(AgentSocietyTextIconComponent);
